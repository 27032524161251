import { InvalidOriginalMapping, OriginalMapping, originalPositionFor, TraceMap } from '@jridgewell/trace-mapping';
import axios                                                                      from 'axios';
import ErrorStackParser                                                           from 'error-stack-parser';
import SparkMD5                                                                   from 'spark-md5';
import { sprintf }                                                                from 'sprintf-js';
import StackFrame                                                                 from 'stackframe';

import { LogLevel } from '@/utils/logger';
import getRoute     from '@/utils/routing';

interface SourceMap {
    filename: string;
    map: string;
}

class ErrorLogger {
    sourceMaps: SourceMap[] = [];

    /**
     * Submit error log to backend
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async submitToBackend(message: string, payload: any, logLevel: LogLevel) {
        const body = {
            message: message,
            level:   logLevel,
            context: payload,
            url:     document.URL,
        };

        const hashString = sprintf(
            '%s%s%s%s',
            message,
            window.shopShortcode,
            logLevel,
            document.URL,
        );

        await axios.post(
            getRoute('api_v1_logs_log_error'),
            body,
            { headers: { hash: SparkMD5.hash(hashString) } },
        );
    }

    /**
     * Get the value of the sourceMappingURL comment from a JavaScript source file
     */
    private extractSourceMapURL(jsSource: string): string | null {
        const sourceMappingUrlMatch = jsSource.match(/\/\/# sourceMappingURL=(.*)/);

        return sourceMappingUrlMatch && sourceMappingUrlMatch[1] ? sourceMappingUrlMatch[1].trim() : null;
    }

    /**
     * Get the inline sourcemap decoded from Base64
     */
    private extractInlineSourceMap(inlineSourceMapPrefix: string, sourceMapUrl: string): string {
        const base64SourceMap = sourceMapUrl.slice(inlineSourceMapPrefix.length);

        return atob(base64SourceMap);
    }

    /**
     * Download the external sourcemap for a JavaScript file
     */
    private async extractExternalSourceMap(sourceMapUrl: string): Promise<string> {
        try {
            const response = await axios.get<string>(sourceMapUrl);

            return response.data;
        } catch (error) {
            throw new Error('Could not fetch external source map "' + sourceMapUrl + '":' + (error as Error).message);
        }
    }

    /**
     * Determines where the sourcemap for the JavaScript file is to be found,
     * fetches it and returns a SourceMap object
     */
    private async fetchSourcemap(filename: string): Promise<SourceMap> {
        const jsSource = await axios.get<string>(filename);
        const sourceMapUrl = this.extractSourceMapURL(jsSource.data);

        if (sourceMapUrl) {
            const inlineSourceMapPrefix = 'data:application/json;charset=utf-8;base64,';
            if (sourceMapUrl.startsWith(inlineSourceMapPrefix)) {
                // Sourcemap is inline
                const map = this.extractInlineSourceMap(inlineSourceMapPrefix, sourceMapUrl);

                return { filename, map };
            } else if (sourceMapUrl.startsWith('http') || sourceMapUrl.startsWith('/')) {
                // Sourcemap URL is external
                const map = await this.extractExternalSourceMap(sourceMapUrl);

                return { filename, map };
            } else {
                // Sourcemap URL is relative
                const baseJsUrl = new URL(filename);
                const fullSourcemapUrl = new URL(sourceMapUrl, baseJsUrl).toString();
                // const fullSourcemapUrl = window.baseUrl + '/' + sourceMapUrl;
                const map = await this.extractExternalSourceMap(fullSourcemapUrl);

                return { filename, map };
            }
        } else {
            let map: string;
            try {
                // No sourcemap defined, try default by appending '.map' to JS file URL
                map = await this.extractExternalSourceMap(filename + '.map');
            } catch {
                // First attempt failed, try finding replacing '.js' with '.map' in JS file URL
                const defaultSourceMapUrl = filename.replace(/\.js$/, '.map');
                map = await this.extractExternalSourceMap(defaultSourceMapUrl);
            }

            return { filename, map };
        }
    }

    /**
     * Get sourcemap from locally cached array of sourcemaps, or call function to download
     */
    private async getSourceMap(filename: string): Promise<SourceMap> {
        let currentSourceMap = this.sourceMaps.find(sourceMap => sourceMap.filename === filename);

        if (currentSourceMap === undefined) {
            currentSourceMap = await this.fetchSourcemap(filename);
            this.sourceMaps.push(currentSourceMap);
        }

        return currentSourceMap;
    }

    /**
     * Use the sourcemaps to trace the original location of a line from the stack
     */
    private async traceLine(filename: string, functionName: string, lineNumber: number, columnNumber: number): Promise<OriginalMapping | InvalidOriginalMapping> {
        let map: SourceMap;
        try {
            map = await this.getSourceMap(filename);
        } catch {
            throw new Error('Could not get sourcemap');
        }

        const tracer = new TraceMap(map.map, filename + '.map');

        return originalPositionFor(tracer, { line: lineNumber, column: columnNumber });
    }

    /**
     * Parse the full stack trace
     */
    private async traceStack(stackFrames: StackFrame[]) {
        const trace: (OriginalMapping | InvalidOriginalMapping)[] = [];
        for (const stackFramesKey in stackFrames) {
            const frame = stackFrames[stackFramesKey];
            const filename = frame.getFileName();
            const functionName = frame.getFunctionName()!;
            const lineNumber = frame.getLineNumber()!;
            const columnNumber = frame.getColumnNumber()!;

            // Set default value as if the sourcemap could not be fetched or parsed
            // We don't try and parse sourcemap of inline JS, or JS executed from console
            let lineTrace: OriginalMapping | InvalidOriginalMapping = {
                source: '<anonymous>',
                name:   functionName,
                column: columnNumber,
                line:   lineNumber,
            };

            if (filename) {
                try {
                    lineTrace = await this.traceLine(filename, functionName, lineNumber, columnNumber);
                    // Remove the URL since we are dealing with a filesystem path
                    lineTrace.source = lineTrace.source?.substring(window.baseUrl.length + 1) || null;
                } catch {
                    // Silence this error
                    // We still want to report the error even if sourcemap could not be fetched/parsed
                }
            }

            trace.push(lineTrace);
        }

        return trace;
    }

    /**
     * Parse an {Error} with full stack trace.
     *
     * NOTE: This does not "handle" the exception.
     *       It will still be uncaught and shown in browsers' dev-tools.
     */
    async captureError(error: Error, logLevel: LogLevel): Promise<void> {
        // Parse the stack trace into an array of StackFrame objects
        const stackTrace = ErrorStackParser.parse(error);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload: Record<string, any> = {
            name:  error.name,
            trace: await this.traceStack(stackTrace),
        };

        if ('code' in error) {
            payload.code = error.code;
        }

        if ('config' in error) {
            payload.config = error.config;
        }

        if ('request' in error) {
            payload.request = error.request;
        }

        if ('response' in error) {
            payload.response = error.response;
        }

        await this.submitToBackend(
            error.message,
            payload,
            logLevel,
        );
    }
}

const errorLogger = new ErrorLogger();

export function captureError(error: Error | undefined, logLevel: LogLevel = LogLevel.ERROR) {
    if (!error || !('name' in error) || !('message' in error) || !('stack' in error)) {
        return;
    }

    return errorLogger.captureError(error, logLevel);
}

export function captureMessage(message: string, logLevel: LogLevel = LogLevel.ERROR) {
    // Throwing an error here generates a stack trace we can use
    let error: Error;
    try {
        throw new Error(message);
    } catch (e) {
        error = e as Error;
    }

    return errorLogger.captureError(error, logLevel);
}

export function resetCache() {
    // @ts-ignore
    if (import.meta.env.TEST) {
        errorLogger.sourceMaps = [];
    }
}

// Attach global event listeners to capture all errors
window.addEventListener('error', (event) => captureError(event.error));
document.addEventListener('error', (event) => captureError(event.error));
